import request from '@/utils/request'
const apiPrefix = `${process.env.VUE_APP_BASE_API}`

/**登录用户相关 */
// 注册
export const getRegister = (params) => {
  return request.post(apiPrefix+'auth/register', params)
}
// 发送验证码
export const sendSMS = (params) => {
  return request.post(apiPrefix+'code/send/sms', params)
}
//登录接口
export const login = (params,config = {}) => {
  return request.post(apiPrefix+'auth/login',params,config)
}
// 获取首页接口
export const getIndex = () => {
  return request.get(apiPrefix+'auth/index')
}
//重置密码
export const rlAdminUserResetPsd = (params,config = {}) => {
  return request.post(apiPrefix+'auth/set/password',params,config)
}
// // 获取登录用户信息
// export const rlAdminUserInfo = (config = {}) => {
//   return request.get('rlAdminUser/get',config)
// }

// 导入接口
// export function apiImportFile(params){
//   return http({
//     url:'/client/ccManage/upload',
//     method:'post',
//     data:params,
//     headers:{
//       'content-type':'multipart/form-data'
//     }
//   })
// }
