import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/styles/index.less'
import '@/assets/icon-font/iconfont.css'
import myPagination from '@/components/my-pagination/my-pagination'
import lodash from 'lodash'
import htmlToPdf from '@/utils/htmlToPdf'

// 将所有ui组件全局注册
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
// 全局注册分页组件
app.component('myPagination',myPagination)
app.config.globalProperties.$lodash = lodash;
app.config.globalProperties.htmlToPdf = htmlToPdf;

// 全局挂载

// 导入全局组件
// import XtxSkeleton from '@/components/Skeleton'
// 导入全局指令
// import DirectivesPlugin from '@/directives'

// createApp(App)
// // .use(XtxSkeleton)
// // .use(DirectivesPlugin)
// .use(router)
// .use(store)
// .use(ElementPlus)
// .mount('#app')

app.use(router)
app.use(store)
app.use(ElementPlus, { locale }).mount('#app')

