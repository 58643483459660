<template>
	<!-- 分页 -->
	<el-pagination
    class="pagination"
	  :layout="currentLayout"
    :page-sizes="sizes"
    :current-page="page"
    :page-size="size"
    :total="total"
    :page-count="pageCount"
    :background="background"
    :small="small"
    @size-change="onSizeChange"
    style="text-align:right;"
    @current-change="onPageChange">
     <template #default>
          <span v-if="checkedCount>0" class="ad-pagination__selection">，已选 {{ checkedCount }} 条</span>
      </template>
	</el-pagination>
</template>

<script>
import {reactive,toRefs,computed} from "vue";
export default {
	name:'myPagination',
	props: {
        layout: {
          type: String,
          default: 'total,  slot, sizes, prev, pager, next, jumper'
        },
        sizes: {
          type: Array,
          default() {
            return [10, 20, 50, 100]
          }
        },
        pageSize: {
          type: Number,
          default: 10
        },
        total: {
          type: Number,
          default: 0
        },
        currentPage: {
          type: Number,
          default: 1
        },
        checkedCount: {
          type: Number,
          default: 0
        },
        background: {
          type: Boolean,
          default: true
        },
        small: {
          type: Boolean,
          default: false
        },
        autoLayout: {
          type: Boolean,
          default: true
        }
    },
	setup(props,context) {
		const $_this = reactive({
            page: props.currentPage,
            size: props.pageSize,
            currentLayout: props.layout
        })
        // 监听属性
        const pageCount = computed(()=>{//标签页显示于否
            return props.total > 0 &&  $_this.size > 0 ? Math.ceil(props.total /  $_this.size): 1
        })
        // 方法
        const methods = {
            onSizeChange(val) {
                $_this.page = 1
                $_this.size = val
                context.emit('size-change', val)
                context.emit('get-page')
            },
            onPageChange(val) {
                 $_this.page = val
                context.emit('page-change', val)
                context.emit('get-page')
            },
            setPage(val) {
                 $_this.page = val
            },
            getPager() {
                return {
                    pageSize:  $_this.size,
                    currentPage:  $_this.page
                }
            }
        }
		return {
            ...toRefs($_this),
            pageCount,
            ...methods
        }
	}
}
</script>
<style lang="less" scoped>
::v-deep .el-pagination__total{
  flex: 1;
  justify-content: flex-start!important;
}
::v-deep .el-pagination__jump.is-last{
  margin-left: 10px;
}
</style>

