import axios from 'axios'
import store from '@/store'
import { toLogout } from '@/router'
import Vue from 'vue'
import { ElMessage } from 'element-plus'
// import { refresh } from '@/api/admin/auth'
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.headers['Pragma'] = 'no-cache'
const requestAxios = axios.create({
  // baseURL: process.env.NODE_ENV === 'development' ? '//120.78.95.74:9115/api/':'//120.78.95.74:9115/api/',
  // baseURL:process.env.VUE_APP_BASE_API,
  timeout: 20000// 请求延时
})
// http://120.78.95.74/api/rlVersion/not/login/get/versionTypel

// 拦截请求
requestAxios.interceptors.request.use(
  config => {
    if (config.api?.auth === false) {
      return config
    }
    const token = store.getters.token
    if (token) {
      // config.headers.Authorization = 'Bearer ' + token
      config.headers.token = token
    }

    return config
  },
  err => {
    return Promise.reject(err)
  }
)

// 拦截响应
requestAxios.interceptors.response.use(
  response => {
    const { config, data } = response
    data.success = data.code === "200" || data.code === 200
    if (!data.success && !config.api?.noErrorMsg && data.msg) {
      const duration = config.api?.msgDuration >= 0 ? config.api?.msgDuration : 3000
      ElMessage.error({
        message: data.msg,
        duration: duration
      })
      if(data.code === '1000' && data.msg === '登录失效'){
        toLogout()
      }
    }
    return data
  },
  async error => {
    const res = { success: false, code: 0, msg: '' }

    if (error?.response) {
      if (error.config._request) {
        return res
      }

      const { config, data, status } = error.response
      if (_.isNumber(status)) {
        res.code = status
      }
      if (_.isPlainObject(data)) {
        _.merge(res, data)
      } else if (_.isString(data)) {
        res.msg = data || error.response.statusText
      }

      // 刷新令牌
      const { code } = res
      if (code === 401) {
        toLogout()
        // const resRefresh = await refresh({ token: store.getters.token })
        // if (resRefresh.code === "1") {
        //   store.commit('user/setToken', resRefresh.data.token)
        //   error.config._request = true
        //   return requestAxios.request(error.config)
        // } else {
        //   toLogout()
        //   return res
        // }
      }

      // 错误消息
      if (!config.api?.noErrorMsg && res.msg) {
        const duration = config.api?.msgDuration >= 0 ? config.api?.msgDuration : 3000
        ElMessage.error({
          message: res.msg,
          duration: duration
        })
      }
    }
    return res
  }
)

export default requestAxios
