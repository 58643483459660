// eslint-disable-next-line no-unused-vars
import { getToken, setToken, removeToken,getExpiredAt, setExpiredAt, removeExpiredAt} from '@/utils/auth'
// import { resetRouter, addRoutes } from '@/router'
// 新特换接口
import { login,getIndex} from '@/api/system/index'


const state = {
  token: getToken(),
  expiredAt:getExpiredAt(),
  // name: '',
  avatar: require('@/assets/images/avatar.png'),
  menus:[],
  permissions: [],
  cunChuTimeFalse:false,
  sleepReport:{},
  userIofoList:{}
}

const mutations = {
  setToken: (state, token) => {
    state.token = token
    setToken(token)
  },
  setExpiredAt: (state, expiredAt) => {
    state.expiredAt = expiredAt
    setExpiredAt(expiredAt)
  },
  // setName: (state, name) => {
  //   state.name = name
  // },
  setAvatar: (state, avatar) => {
    if (avatar) {
      state.avatar = process.env.VUE_APP_AVATAR_URL + avatar
    } else {
      state.avatar = require('@/assets/images/avatar.png')
    }
  },
  setMenus: (state, menus) => {
    state.menus = menus
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions
  },
  setCunChuTimeFalse:(state,cunChuTimeFalse)=>{
    state.cunChuTimeFalse = cunChuTimeFalse
  },
  setSleepReport:(state,sleepReport)=>{
    state.sleepReport = sleepReport
    localStorage.setItem('sleepReport',sleepReport)
  },
  setUserInfoList: (state, userIofoList) => {
    state.userIofoList = userIofoList
  },
}

const actions = {
  // login登录
  async login({ commit }, paras) {
    const res = await login(paras)
    if (res && res.success) {
      const token = res.data.accessToken
      commit('setToken', token)
    }

    return res
  },

  async getLoginInfo({ commit }) {
    // 菜单信息
    const res = await getIndex()
    if (!res?.success) {
      return res
    }
    // commit('setMenus', res.data.menuList)
    commit('setUserInfoList',res.data)
    localStorage.setItem('deLoginInfo',JSON.stringify(res.data))
    return res
  },

  setLocalLoginInfo({ commit, dispatch, state }) {
    let loginInfo = window.localStorage.getItem('deLoginInfo')
    if (loginInfo) {
      loginInfo = JSON.parse(loginInfo)
      commit('setName', loginInfo.adminAccount)
      commit('setAvatar', loginInfo.avatar)
      // commit('setMenus', loginInfo.menuList)
    } else {
      dispatch('logout')
    }
  },

  logout({ commit }) {
    commit('setToken', '')
    removeToken()
    commit('setExpiredAt', '')
    removeExpiredAt()
    // resetRouter()
    sessionStorage.removeItem('tabs')
    localStorage.removeItem('deLoginInfo')

    commit('tabsView/delete_all_cached_views', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
