import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
/**
 * 不分页展示
 * @param  ele          要生成 pdf 的DOM元素（容器）
 * @param  padfName     p要成的pdfDomId
 * @param  name     名称
 * */
function downloadPDF(ele,pdfName,name){
  // 生成的pdf只有页面窗口可见的区域，有滚动条的下面没有生成出来，需在生成PDF前，改overflow属性auto为visible
	// 获取dom高度、宽度
	var shareContent = ele;
	var width = shareContent.offsetWidth / 4;
	var height = shareContent.offsetHeight / 4;
	// eslint-disable-next-line
	// let loadingInstance = Loading.service({text: '正在导出，请稍等'});
	html2canvas(pdfName, {
	// dpi: 900,
	dpi: window.devicePixelRatio*4, //将分辨率提高到特定的DPI 提高四倍
	// scale:4,//按比例增加分辨率
	allowTaint: true,
	taintTest: false,
	useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
	// backgroundColor: null //避免图片有白色边框
	}).then((canvas) => {
		var context = canvas.getContext('2d');
		context.mozImageSmoothingEnabled = false;
		context.webkitImageSmoothingEnabled = false;
		context.msImageSmoothingEnabled = false;
		context.imageSmoothingEnabled = false;
		var pageData = canvas.toDataURL('image/jpeg', 1.0);
		var img = new Image();
		img.src = pageData;
		img.onload = ()=> {
			// 获取dom高度、宽度
			img.width = img.width / 2;
			img.height = img.height / 2;
			img.style.transform = 'scale(0.5)';
			if (width > height) {
				// 此可以根据打印的大小进行自动调节
				// eslint-disable-next-line
				var pdf = new JsPDF('l', 'mm', [width * 0.505, height * 0.545]);
			} else {
				// eslint-disable-next-line
				var pdf = new JsPDF('p', 'mm', [width * 0.505, height * 0.545]);
			}
			pdf.addImage(pageData, 'jpeg', 0, 0, width * 0.505, height * 0.545);
			pdf.save(name  + '.pdf');
			// loadingInstance.close();
		};
	});
}

export default {
  downloadPDF
}

