const tokenKey = 'deToken'

export function getToken() {
  return window.localStorage.getItem(tokenKey)
}

export function setToken(token) {
  return window.localStorage.setItem(tokenKey, token)
}

export function removeToken() {
  return window.localStorage.removeItem(tokenKey)
}

export function getExpiredAt() {
  return window.localStorage.getItem('expiredAt2')
}

export function setExpiredAt(expiredAt) {
  return window.localStorage.setItem('expiredAt2', expiredAt)
}

export function removeExpiredAt() {
  return window.localStorage.removeItem('expiredAt2')
}